import REQ from '@/app/REQ'
import { REQP } from '../services/config'
import {parseUrlParams} from '../utils/xyhUtils'
import {mapState} from "vuex";
import {PRODOUCT} from "@/consts";

const WxApiBaseUrl= XyhConf?.loginWxApp?.wxsUrl || 'https://open.weixin.qq.com/connect/qrconnect'
const WxApp=XyhConf.loginWxApp
const transitUrl=`${XyhConf.wxLoginReToUrl}/?bkMode=hash`

export default {
	name: "login",
	props: ['wxCode'],

	data() {
		let s = location.href
		let selfUrl = s.substr(0, s.indexOf('#'))
		return {
			isShowSmsLogin: false,
			wxLoginUrl: `${WxApiBaseUrl}?appid=${WxApp.id}&redirect_uri=${encodeURIComponent(transitUrl)}&response_type=code&scope=snsapi_login&state=${encodeURIComponent(selfUrl)}#wechat_redirect`,
			userName: "",
			pwd: "",
			msg: "",
			time:60,
			getCode:false,
			verifiyCode:'发送验证码',
			orgs:[],
			orgIdx:'',
			staffs:[],
			switchOrg:false,
			newSiteUrl:XyhConf.newSiteUrl,
		};
	},

	computed:{
		...mapState({orgProducts: ({ curOrg }) => curOrg?.products}),
	},

	created(){
		if(this.wxCode) return this.loginByWxCode(this.wxCode)

		let ps = parseUrlParams(location.search)
		if(ps.code && ps.wxlogin==1) this.loginByWxCode(ps.code.wxCode)
	},

	methods: {

		isPoneAvailable(pone) {
			let myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
			if (!myreg.test(pone)) {
				return false;
			} else {
				return true;
			}
		},

		onSendVeriSms() {
			if (this.userName && this.userName.length==11) {
				this.postReqR(REQ.sendVeriSms, {mobile: this.userName},()=>{
					this.getCode=true
					setInterval(()=>{
						if(this.time<=0){
							this.time=60
							this.getCode=false
							this.verifiyCode='重新发送'
							return
						}else {
							this.time--
						}
					},1000)
				})
			} else {
				this.$message("请输入正确的手机号码");
				return;
			}
		},


		loginByWxCode(wxCode) {
			this.postReq(REQP.loginByWx, {wxCode, wxApp: WxApp.n}, ({token, myself, orgs, staffs}) => {
				this.setCurUser(myself, token)
				if(orgs.length<=0){
					return this.$router.push('/personal/attendOrg')
				}else if (orgs.length >1){
					this.orgs = orgs
					this.staffs = staffs
					this.switchOrg = true
				}else {
					this.setCurOrg(orgs[0], staffs[0])
					if (this.orgProducts.find(it => it == PRODOUCT.sop)) {
						this.$router.push('/main/myDocPrjts')
					} else if (this.orgProducts.find(it => it == PRODOUCT.stone)) {
						this.$router.push('/main/stoneMarkState')
					} else {
						this.$router.push('/personal/personalPage')
					}
				}
			})
		},

		switchSmsAndPwd(){
			this.pwd='';
			this.isShowSmsLogin=!this.isShowSmsLogin;
		},

		onLogin: function () {
			if (this.userName == "" || this.pwd == "")
				return this.msg = "用户名密码不能为空";

			if(!this.isShowSmsLogin){
				this.postReq(REQP.login, {user: this.userName, password: this.pwd}, ({token, myself, staffs, orgs}) => {
					this.setCurUser(myself, token)
					if(orgs.length<=0){
						return this.$router.push('/personal/attendOrg')
					} else if (orgs.length >1){
						this.orgs = orgs
						this.staffs = staffs
						this.switchOrg = true
					}else {
						this.setCurOrg(orgs[0], staffs[0])
						if(this.orgProducts.find(it=>it == PRODOUCT.sop)){
							this.$router.push('/myDocPrjts')
						}else if (this.orgProducts.find(it=>it == PRODOUCT.stone)){
							this.$router.push('/main/stoneMarkState')
						}else {
							this.$router.push('/personal/personalPage')
						}
					}
				})
			}else {
				this.postReqR(REQ.loginBySms,{mobile:this.userName, veriCode: this.pwd},({token, myself, staffs, orgs}) => {
					this.setCurUser(myself, token)
					if(orgs.length<=0){
						return this.$router.push('/personal/attendOrg')
					}else if (orgs.length >1){
						this.orgs = orgs
						this.staffs = staffs
						this.switchOrg = true
					}else {
						this.setCurOrg(orgs[0], staffs[0])
						if(this.orgProducts.find(it=>it == PRODOUCT.sop)){
							this.$router.push('/main/myDocPrjts')
						}else if (this.orgProducts.find(it=>it == PRODOUCT.stone)){
							this.$router.push('/main/stoneMarkState')
						}else {
							this.$router.push('/personal/personalPage')
						}
					}
				})
			}
		},

		onOrgSeled() {
			let {orgs,orgIdx, staffs} = this
			if(orgIdx >=0) {
				let org = orgs[orgIdx]
				let staff = staffs.find(it=>it.oid == org.id)
				this.setCurOrg(org, staff)
				if(this.orgProducts.find(it=>it == PRODOUCT.sop)){
					this.$router.push('/main/myDocPrjts')
				}else if (this.orgProducts.find(it=>it == PRODOUCT.stone)){
					this.$router.push('/main/stoneMarkState')
				}else {
					this.$router.push('/personal/personalPage')
				}
			}
			this.switchOrg = false
		},
	}
};
